import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
//MUI
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
//Stack notificactions
import { SnackbarProvider, useSnackbar } from "notistack";
//Libraries
import i18n from "./Libraries/Translation/i18n";
//Components
import BaseContainer from "./React/Modules/base/containers/BaseContainer";
import HomeContainer from "./React/Modules/home/HomeContainer";
// import LoginContainer from "./React/Modules/login/LoginContainer";
import SearchComponent from "./React/Modules/navigation/components/SearchComponent";
// import NewAccount from "./React/Modules/login/components/NewAccount";
import CustomerSupport from "./React/Modules/navigation/components/Footer/CustomerSupport";
import ZcareContainer from "./React/Modules/zcare/ZcareContainer";
import FAQ from "./React/Modules/navigation/components/Footer/FAQ";
import SalesSpot from "./React/Modules/navigation/components/Footer/SalesSpot";
import ProductsContainer from "./React/Modules/products/ProductsContainer";
import AboutsUsContainter from "./React/Modules/aboutUs/AboutsUsContainter";
import ProfileContainer from "./React/Modules/profile/ProfileContainer";
import UserProfileContainer from "./React/Modules/profile/UserProfileContainer";
import FavoritesContainer from "./React/Modules/profile/FavoritesContainer";
import FriendsReferredContainer from "./React/Modules/profile/FriendsReferredContainer";
import ShoppingContainer from "./React/Modules/profile/ShoppingContainer";
import DetailShopping from "./React/Modules/profile/DetailShopping";
import ShoppingRepaymentContainer from "./React/Modules/profile/Repayment/ShoppingRepaymentContainer";
import ListProductsContainer from "./React/Modules/products/components/ListProductsContainer";
import DetailProductContainer from "./React/Modules/products/DetailProductContainer";
import CheckOutContainer from "./React/Modules/checkout/container";
//import KitConfiguration from "./React/Modules/products/components/KitConfiguration";
// import MembersContainer from "./React/Modules/members/MembersContainer";
import PrivacyPolicesConatiner from "./React/Modules/navigation/components/Footer/PrivacyPolicesConatiner";
import TermsAndConditionsContainer from "./React/Modules/navigation/components/Footer/TermsAndConditionsContainer";
import PolicesShippingContainer from "./React/Modules/navigation/components/Footer/PolicesShippingContainer";
import ReferredContainer from "./React/Modules/Referred/ReferredContainer";

//import BlockGral from "./React/Modules/base/components/BlockGral";
// import NewMembersContainer from "./React/Modules/membersOld/NewMembersContainer";
import NewKitsConfiguration from "./React/Modules/base/components/Kits/NewKitsConfiguration";
// import SummaryShoppingContainer from "./React/Modules/products/components/SummaryShoppingContainer";
import PersonalizedFragrances from "./React/Modules/products/components/PersonalizedFragrances";
///New Login
import NewLoginStep from "./React/Modules/products/components/Checkout/NewLoginStep";
//New members v2
import NewMembersMain from "./React/Modules/newMembers/NewMembersMain";
import Gifts from "./React/Modules/products/components/Gifts";
///New Summary
import NewSummaryShoppingContainer from "./React/Modules/products/NewSummaryShoppingContainer";
/// Recovery password
import RecoveryPassword from "./React/Modules/login/components/RecoveryPassword";
import NewResetPassword from "./React/Modules/login/components/NewResetPassword";
//// New Favorite Customer
import NewFavoriteCustomerContainer from "./React/Modules/favoriteCustomer/NewFavoriteCustomerContainer";
// New Enterprising
import NewEnterprisingContainer from "./React/Modules/enterprising/NewEnterprisingContainer";
///New Referred Friends
import NewReferredFriends from "./React/Modules/profile/components/NewReferredFriends";
// Landing Enterprising
import LandingEnterprisingContainer from "./React/Modules/landing/LandingEnterprisingContainer";
//Styles
import "./assets/styles/css/main.min.css";
import personalityTestContainer from "./React/Modules/personalityTest/PersonalityTestContainer";
import PersonalityTestResults from "./React/Modules/personalityTest/components/PersonalityTestResults";

const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close />
    </IconButton>
  );
};

export default function Routes() {
  return (
    <SnackbarProvider
      maxSnack={4}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      action={(snackbarKey) => (
        <SnackbarCloseButton snackbarKey={snackbarKey} />
      )}
      //preventDuplicate
    >
      <Router
        basename="/"
        getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}
      >
        {/* <ScrollToTop> */}
        <Switch>
          {/* <Route
            exact
            path="/"
            render={() => (
              <BlockGral/>
            )}
          /> */}
          {/* //v2 
          <Route
            exact
            path="/"
            render={() => (
              <BaseContainer>
                <HomeContainer />
              </BaseContainer>
            )}
          /> */}

          <Route exact path="/">
            <Redirect to="/home" />
          </Route>

          <Route
            exact
            path="/home"
            render={() => (
              <BaseContainer>
                <HomeContainer />
              </BaseContainer>
            )}
          />

          <Route
            exact
            path="/mypage/:webPage"
            render={() => (
              <BaseContainer>
                <HomeContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/example"
            render={() => (
              <BaseContainer>
                <NewLoginStep />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/precheckout"
            render={() => (
              <BaseContainer>
                <NewLoginStep />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/login"
            render={() => (
              <BaseContainer>
                <NewLoginStep />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/login/:targetRoute"
            render={() => (
              <BaseContainer>
                <NewLoginStep />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/login/:targetRoute/:opt"
            render={() => (
              <BaseContainer>
                <NewLoginStep />
              </BaseContainer>
            )}
          />

          <Route
            exact
            path="/gifts"
            render={() => (
              <BaseContainer>
                <Gifts />
              </BaseContainer>
            )}
          />

          {/* <Route
            exact
            path="/loginold"
            render={() => (
              <BaseContainer>
                <LoginContainer inCheckout={false} />
              </BaseContainer>
            )}
          /> */}

          <Route
            exact
            path="/search"
            render={() => (
              <BaseContainer>
                <SearchComponent />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/recovery"
            render={() => (
              <BaseContainer>
                <RecoveryPassword />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/reset"
            render={() => (
              <BaseContainer>
                <NewResetPassword />
                {/* <ResetPassword /> */}
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/newReferred/:referrerCode"
            render={(props) => (
              <BaseContainer>
                <NewReferredFriends {...props} />
              </BaseContainer>
            )}
          />
          {/* <Route
            exact
            path="/newAccount"
            render={() => (
              <BaseContainer>
                <NewAccount inCheckout={false} />
              </BaseContainer>
            )}
          /> */}
          <Route
            exact
            path="/customerSupport"
            render={() => (
              <BaseContainer>
                <CustomerSupport />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/zcare"
            render={() => (
              <BaseContainer>
                <ZcareContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/contactus"
            render={() => (
              <BaseContainer>
                <HomeContainer />
              </BaseContainer>
            )}
          />

          <Route
            exact
            path="/faq"
            render={() => (
              <BaseContainer>
                <FAQ />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/salesSpot"
            render={() => (
              <BaseContainer>
                <SalesSpot />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/categories"
            render={() => (
              <BaseContainer>
                <ProductsContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/product-detail/:itemCode/:typeLine"
            render={() => (
              <BaseContainer>
                <DetailProductContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/product-detail/:webPage/:itemCode/:typeLine"
            render={() => (
              <BaseContainer>
                <DetailProductContainer />
              </BaseContainer>
            )}
          />
          {/* <Route
            exact
            path="/trends"
            render={() => (
              <BaseContainer>
                <TrendsContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/promotions"
            render={() => (
              <BaseContainer>
                <PromotionsContainer />
              </BaseContainer>
            )}
          /> */}
          <Route
            exact
            path="/aboutUs"
            render={() => (
              <BaseContainer>
                <AboutsUsContainter />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/favoriteCustomer"
            render={() => (
              <BaseContainer>
                <NewFavoriteCustomerContainer />
                {/* <FavoriteCustomerContainer /> */}
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/favoriteCustomer/:webPage"
            render={() => (
              <BaseContainer>
                <NewFavoriteCustomerContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/enterprising"
            render={() => (
              <BaseContainer>
                <NewEnterprisingContainer isEntreprising={true} />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/enterprising/:webPage"
            render={() => (
              <BaseContainer>
                <NewEnterprisingContainer isEntreprising={true} />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/profile"
            render={() => (
              <BaseContainer>
                <ProfileContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/profile/my-profile"
            render={() => (
              <BaseContainer>
                <UserProfileContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/profile/my-favorites-list"
            render={() => (
              <BaseContainer>
                <FavoritesContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/profile/friends-referred"
            render={() => (
              <BaseContainer>
                <FriendsReferredContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/profile/shopping-list"
            render={() => (
              <BaseContainer>
                <ShoppingContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/profile/shopping-list/detail/:idPedido"
            render={() => (
              <BaseContainer>
                <DetailShopping />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/profile/shopping-list/repayment/:idPedido"
            render={() => (
              <BaseContainer>
                <ShoppingRepaymentContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/categories/products"
            render={() => (
              <BaseContainer>
                <ListProductsContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/categories/products/:categoryId"
            render={() => (
              <BaseContainer>
                <ListProductsContainer />
              </BaseContainer>
            )}
          />
          {/* <Route
            exact
            path="/checkoutold"
            render={() => (
              <BaseContainer>
                <CheckoutContainerOld />
              </BaseContainer>
            )}
          /> */}
          <Route
            exact
            path="/checkout"
            render={() => (
              <BaseContainer>
                <CheckOutContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/summary/:sessionId/:orderCloseType"
            render={() => (
              <BaseContainer>
                <NewSummaryShoppingContainer />
                {/* <SummaryShoppingContainer /> */}
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/kit-config/:itemCode/:typeLine"
            render={() => (
              <BaseContainer>
                <NewKitsConfiguration isEntreprising={true} />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/privacy-polices"
            render={() => (
              <BaseContainer>
                <PrivacyPolicesConatiner />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/shipping-polices"
            render={() => (
              <BaseContainer>
                <PolicesShippingContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/terms-and-conditions"
            render={() => (
              <BaseContainer>
                <TermsAndConditionsContainer />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/personalized-fragance"
            render={() => (
              <BaseContainer>
                <PersonalizedFragrances />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/personalized-fragance/it/:itemCode"
            render={() => (
              <BaseContainer>
                <PersonalizedFragrances />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/personalized-fragance/od/:orderId/:orderDetailId"
            render={() => (
              <BaseContainer>
                <PersonalizedFragrances />
              </BaseContainer>
            )}
          />
          <Route
            exact
            path="/referred/:referredCode"
            render={() => (
              <BaseContainer>
                <ReferredContainer />
              </BaseContainer>
            )}
          />

          {/* <Route
            exact
            path="/NewMembersOld/:userWebPage"
            component={NewMembersContainer}
          /> */}

          {/* NEW MEMBERS V2 */}
          {/* <Route
            exact
            path="/NewMembers/:userWebPage"
            component={NewMembersMain}
          /> */}

          {/* landing */}
          <Route
            exact
            path="/newRegistration"
            component={LandingEnterprisingContainer}
          />
          <Route
            exact
            path="/newRegistration/:userWebPage"
            component={LandingEnterprisingContainer}
          />

          {/* <Route
            exact
            path="/NewMembers/:userWebPage/:targetRoute"
            component={NewMembersMain}
          /> */}
          {/* NEW MEMBERS V2 */}

          {/* <Route
            exact
            path="/Registration/:userWebPage"
            render={(props) => <MembersContainer {...props} />}
          /> */}
          {/*  V3 */}

          <Route
            exact
            path="/personalityTest"
            component={personalityTestContainer}
          />

          <Route
            exact
            path="/personalityTest/:userWebPage"
            component={personalityTestContainer}
          />

          <Route
            exact
            path="/personalityTestResults"
            component={PersonalityTestResults}
          />

          <Route
            exact
            path="/personalityTestResults/:userId/:uuid"
            component={PersonalityTestResults}
          />

          <Route
            path="*"
            render={({ location }) => {
              if (location.pathname.includes("/mypage/mypage/")) {
                window.location.replace(
                  `/${window.location.pathname.split("/").pop()}`
                );
              } else {
                window.location.replace(`/mypage${location.pathname}`);
              }
            }}
          />
        </Switch>
        {/* </ScrollToTop> */}
      </Router>
    </SnackbarProvider>
  );
}
