import { call, put, takeLatest } from "redux-saga/effects";
import {
    REQUEST_PERSONALITY_TEST_CONFIG,
    RESPONSE_PERSONALITY_TEST_CONFIG,
    REQUEST_SUBMIT_SURVEY_DATA,
    RESPONSE_SUBMIT_SURVEY_DATA,
    REQUEST_SURVEY_PROFILE_DETAILS,
    RESPONSE_SURVEY_PROFILE_DETAILS,
    DATA_FETCH_FAILED
} from "../../Constants";
import PersonalityTest from "../../../../Libraries/PersonalityTest";

function* requestSurveyContent() {
    try {
        const response = yield call(PersonalityTest.getSurveyContent);
        yield put({
            type: RESPONSE_PERSONALITY_TEST_CONFIG,
            payload: { response: response.getSurveyContent },
        });
    } catch (error) {
        console.error("requestSurveyContent error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
    }
}

function* submitSurveyData(data) {
    try {
        const response = yield call(PersonalityTest.submitSurveyData, data.payload.model);
        yield put({
            type: RESPONSE_SUBMIT_SURVEY_DATA,
            payload: { response: response.submitSurveyData },
        });
    } catch (error) {
        console.error("submitSurveyData error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
    }
}

function* requestSurveyProfileDetails(data) {
    try {
        const response = yield call(PersonalityTest.getSurveyProfileDetails, data.payload.model);
        yield put({
            type: RESPONSE_SURVEY_PROFILE_DETAILS,
            payload: { response: response.surveyProfileDetails },
        });
    } catch (error) {
        console.error("requestSurveyProfileDetails error", error);
        yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
    }
}

export function* watchRequestSurveyContent() {
    yield takeLatest(REQUEST_PERSONALITY_TEST_CONFIG, requestSurveyContent);
}

export function* watchSubmitSurveyData() {
    yield takeLatest(REQUEST_SUBMIT_SURVEY_DATA, submitSurveyData);
}

export function* watchRequestSurveyProfileDetails() {
    yield takeLatest(REQUEST_SURVEY_PROFILE_DETAILS, requestSurveyProfileDetails);
}