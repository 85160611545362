import React from "react";
import { useTranslation } from "react-i18next";
//MUI
import { Skeleton } from "@mui/material";

const PersonalityTestFooter = ({ isLanguageLoaded }) => {
  //Translation
  const { t } = useTranslation();

  return (
    <div className="personality__test__container__footer">
      <div className="inner">
        {isLanguageLoaded ? (
          <span>{t("__new__members__footer__follow__zermat")}</span>
        ) : (
          <Skeleton width={150} />
        )}

        <a target="_black" href="https://www.instagram.com/zermatus/?hl=en">
          <div className="instagramBlack"></div>
        </a>
        <a
          target="_black"
          href="https://www.tiktok.com/@zermatusa_official?lang=en"
        >
          <div className="tiktokBlack"></div>
        </a>
        <a target="_black" href="https://www.facebook.com/zermatusa/">
          <div className="facebookBlack"></div>
        </a>
        <a
          target="_black"
          href="https://www.youtube.com/channel/UCNC80gWIoSGS8tO4oU_TkCg"
        >
          <div className="youtubeBlack"></div>
        </a>
        <a target="_black" href="https://mx.pinterest.com/zermatusa/">
          <div className="pinterestBlack"></div>
        </a>
      </div>
    </div>
  );
};

export default PersonalityTestFooter;
