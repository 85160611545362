import {
  RESPONSE_TWILIO_CELLPHONE_VALIDATION,
  RESPONSE_TWILIO_CELLPHONE_CODE_VALIDATION,
  RESPONSE_TWILIO_ONLY_CELLPHONE_VALIDATION
} from "../../Constants";

export default function twilio(state = {}, action) {
  switch (action.type) {
    case RESPONSE_TWILIO_CELLPHONE_VALIDATION: {
      const responseTwilioCellValidation = action.payload.response;
      const code = responseTwilioCellValidation.code;
      if (code === 100 || code === 110) {
        return { ...state, errorTwilioCellValidation: false, code };
      } else {
        console.error(
          "error al validar el numero celular",
          responseTwilioCellValidation
        );
        return { ...state, errorTwilioCellValidation: true, code };
      }
    }
    case RESPONSE_TWILIO_CELLPHONE_CODE_VALIDATION: {
      const responseTwilioCellCodeValidation = action.payload.response;
      if (responseTwilioCellCodeValidation.code === 100) {
        return { ...state, errorTwilioCellCodeValidation: false };
      } else {
        console.error(
          "error al validar el codigo enviado por twilio",
          responseTwilioCellCodeValidation
        );
        return { ...state, errorTwilioCellCodeValidation: true };
      }
    }
    case RESPONSE_TWILIO_ONLY_CELLPHONE_VALIDATION: {
      const responseTwilioCellValidation = action.payload.response;
      const code = responseTwilioCellValidation.code;
      if (code === 100 || code === 110) {
        return { ...state, errorTwilioCellValidation: false };
      } else {
        console.error(
          "error al validar el numero celular",
          responseTwilioCellValidation
        );
        return { ...state, errorTwilioCellValidation: true };
      }
    }
    default:
      return { ...state };
  }
}
