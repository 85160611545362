import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// Components
import PersonalityTestHeader from "./components/PersonalityTestHeader";
import PersonalityTestQuestions from "./components/PersonalityTestQuestions";
import PersonalityTestForm from "./components/PersonalityTestForm";
import PersonalityTestFooter from "./components/PersonalityTestFooter";

// Actions
import { requestEntrepenourHomeModuleInfo } from "./../../ReduxSaga/Actions/Home";
import { requestClientLogoutAndChargeNewMember } from "./../../ReduxSaga/Actions/Client";
import { requestSurveyContent } from "../../ReduxSaga/Actions/PersonalityTest";

const PersonalityTestContainer = ({ match }) => {
  // Translation
  const { t, i18n } = useTranslation();

  //React Router
  const history = useHistory();

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.home = useSelector((state) => state.home);
  appReducers.client = useSelector((state) => state.client);
  appReducers.personalityTest = useSelector((state) => state.personalityTest);
  appReducers.newMember = useSelector((state) => state.newMember);

  // Hooks
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const [sponsorData, setSponsorData] = useState({});
  const [currentStepPage, setCurrentStepPage] = useState(0);
  const [surverAnswers, setSurverAnswers] = useState({});
  const [surveyData, setSurveyData] = useState({});

  // Effects
  useEffect(() => {
    handleGetSurveyContent();
  }, []);

  // Efecto cargar configuracion de la encuesta
  useEffect(() => {
    if (
      typeof appReducers?.personalityTest?.errorLoadingSurveyContent !==
      undefined
    ) {
      if (!appReducers?.personalityTest?.errorLoadingSurveyContent) {
        if (appReducers?.personalityTest?.surveyData) {
          setSurveyData(appReducers?.personalityTest?.surveyData);
        } else {
          setSurveyData({});
        }
      }
    }
    return () => {
      if (
        appReducers?.personalityTest?.surveyData == null ||
        appReducers?.personalityTest?.surveyData == undefined
      ) {
        setSurveyData({});
      }
    };
  }, [
    appReducers?.personalityTest?.errorLoadingSurveyContent,
    appReducers?.personalityTest?.surveyData,
  ]);

  // Cargamos idioma
  useEffect(() => {
    if (i18n.resolvedLanguage) {
      setIsLanguageLoaded(true);
      handleFirstCharge();
    }
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (appReducers?.general?.firstChargeModel?.hasOwnProperty("idLang")) {
      if (appReducers?.general?.firstChargeModel?.idPage > 0) {
        dispatch(requestEntrepenourHomeModuleInfo());
      } else {
        console.error("No se encontró la página web del lider");
        localStorage.clear();
        history.replace(`/personalityTest`);
      }
    }
    return;
  }, [
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
    i18n,
  ]);

  //*** SET INFORMACION PAGINA ***//
  useEffect(() => {
    if (appReducers?.home?.entrepenourPageInfo) {
      setSponsorData(appReducers?.home?.entrepenourPageInfo);
    }

    return () => {
      if (sponsorData.hasOwnProperty("nameClient")) {
        setSponsorData({});
      }
    };
  }, [appReducers?.home?.entrepenourPageInfo]);

  const handleGetSurveyContent = () => {
    dispatch(requestSurveyContent());
  };

  const handleFirstCharge = () => {
    let urlPage = "";
    if (match?.params?.hasOwnProperty("userWebPage")) {
      urlPage = match.params.userWebPage;
    }
    if (urlPage.length <= 0 && localStorage.getItem("ZUsaUrlPage")) {
      urlPage = localStorage.getItem("ZUsaUrlPage");
      history.replace(
        `/personalityTest/${localStorage.getItem("ZUsaUrlPage")}`
      );
    }
    dispatch(requestClientLogoutAndChargeNewMember(urlPage));
  };

  return (
    <div className="personality__test__container">
      <PersonalityTestHeader
        isLanguageLoaded={isLanguageLoaded}
        surveyData={surveyData}
        sponsorData={sponsorData}
        t={t}
        i18n={i18n}
      />

      {/* BODY */}
      {currentStepPage === 0 ? (
        <PersonalityTestQuestions
          setCurrentStepPage={setCurrentStepPage}
          surverAnswers={surverAnswers}
          setSurverAnswers={setSurverAnswers}
          surveyData={surveyData}
          t={t}
          i18n={i18n}
          isLanguageLoaded={isLanguageLoaded}
        />
      ) : (
        <PersonalityTestForm
          sponsorData={sponsorData}
          surveyData={surveyData}
          surverAnswers={surverAnswers}
          t={t}
          i18n={i18n}
        />
      )}

      {/* FOOTER */}
      <PersonalityTestFooter isLanguageLoaded={isLanguageLoaded} />
    </div>
  );
};

export default PersonalityTestContainer;
