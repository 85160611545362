import GraphqlHandler from "../GraphqlHandler";
import GraphqlHandlerNoInterceptor from "../GraphqlHandler/noInterceptor";
import { ENVIROMENT_URL } from "../../React/ReduxSaga/Constants";

const route = ENVIROMENT_URL + "apiV3/PersonalityTestMicroservice/PersonalityTest";

class PersonalityTest{
    static getSurveyContent() {
        const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
          route,
          //'https://localhost:44388/PersonalityTest',
          `
        query {
        getSurveyContent{
            code
            message
            surveyData{
              idSurvey
              guid
              nameSpanish
              nameEnglish
              titleSpanish
              titleEnglish
              titleContactInformationSpanish
              titleContactInformationEnglish
              descriptionSpanish
              descriptionEnglish
              urlImgSpanish
              urlImgEnglish
              urlImgSpanishMobile
              urlImgEnglishMobile
              subtitleContactInformationEnglish
              subtitleContactInformationSpanish
              titleResultEnglish
              titleResultSpanish
              questions{
                idQuestions
                textEnglish
                textSpanish
                answers{
                  idAnswers
                  textEnglish
                  textSpanish
                  answerType{
                    idAnswerType
                    answerType
                  }
                  idQuestions
                }
              }
            }
          }
        }
          `,
          false
        );
        return result;
      }
  
      static submitSurveyData(model) {
        const result = GraphqlHandlerNoInterceptor.openRequestGraphQlMutation(
          route,
          `(@autodeclare) {
            submitSurveyData(person: $person, surveyData: $surveyData) {
              code,
              message,
              guid
            }
          }
            `,
          true,
          {
            "person!PersonInput": model.person,
            "surveyData![SurveyDataInput]": model.surveyData
          }
        );
        return result;
      }

      static getSurveyProfileDetails(model) {
        const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
          route,
          `(@autodeclare)
            {
            surveyProfileDetails(idPerson: $idPerson, guid: $guid) {
              code,
              message,
              surveyResultDataModel {
                survey {
                  idSurvey,
                  guid,
                  nameSpanish,
                  nameEnglish,
                  titleResultSpanish,
                  titleResultEnglish,
                  urlImgSpanish,
                  urlImgEnglish,
                  urlImgSpanishMobile,
                  urlImgEnglishMobile,
                },
                profile {
                  idPerson,
                  idProfile,
                  nameSpanish,
                  nameEnglish,
                  urlImgSpanish,
                  urlImgEnglish,
                  urlImgSpanishMobile,
                  urlImgEnglishMobile,
                  gender,
                  idLang
                },
                products {
                  sku,
                  anchorArticle,
                  descArticleSpanish,
                  descArticleEnglish,
                  nameArticleSpanish,
                  nameArticleEnglish,
                  regularPrice,
                  urlArticle,
                  urlImage
                }
              }
            }
          }
          `,
          true,
          {
            "idPerson!Int": model.idPerson,
            "guid!String": model.guid,
          }
        );
        
        return result;
      }
}

export default PersonalityTest;