import React, { useEffect, useRef, useState } from "react";
//MUI
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow } from "swiper";
import "swiper/swiper-bundle.css";
import { Skeleton } from "@mui/material";

const PersonalityTestQuestions = ({
  setCurrentStepPage,
  surverAnswers,
  setSurverAnswers,
  surveyData,
  i18n,
  t,
  isLanguageLoaded,
}) => {
  //Swiper
  const swiperRef = useRef();

  //State
  const [currentQuestionsIndex, setCurrentQuestionsIndex] = useState(0);
  const [questions, setQuestions] = useState([]);

  //efect
  useEffect(() => {
    // Verifica si surveyData está definido antes de acceder a questions
    if (surveyData && Array.isArray(surveyData.questions)) {
      setQuestions(surveyData.questions);
    } else {
      setQuestions([]); // Set an empty array if questions is not available
    }
  }, [surveyData]);

  //Handlers
  const handleGoToPrevSlide = () => {
    if (currentQuestionsIndex > 0)
      setCurrentQuestionsIndex(currentQuestionsIndex - 1);
    swiperRef.current.slideTo(currentQuestionsIndex - 1);
  };

  const handleGoToNextSlide = () => {
    if (currentQuestionsIndex < questions.length - 1)
      setCurrentQuestionsIndex(currentQuestionsIndex + 1);
    swiperRef.current.slideTo(currentQuestionsIndex + 1);
  };

  const handleAnswerSelect = (e) => {
    const { name, value } = e.target;
    setSurverAnswers({ ...surverAnswers, [name]: value });
    handleGoToNextSlide();
  };

  //Renders
  const renderQuestions = () => {
    return questions.map((item, index) => {
      return (
        <SwiperSlide key={item.idQuestions}>
          {i18n?.language == "es" ? (
            <div className="questions__container__title">
              <span>{item.textSpanish}</span>
            </div>
          ) : (
            <div className="questions__container__title">
              <span>{item.textEnglish}</span>
            </div>
          )}
          <div className="questions__container__answers">
            {item.answers.map((answer) => {
              return (
                <div
                  key={answer.idAnswers}
                  className="questions__container__answers__item"
                >
                  <input
                    type="radio"
                    name={`question${index}`}
                    value={
                      surveyData?.idSurvey +
                      "|" +
                      item.idQuestions +
                      "|" +
                      answer.idAnswers
                    }
                    onChange={handleAnswerSelect}
                  />
                  {answer.answerType.idAnswerType === "2" ? (
                    <div className="color__palette">
                      {answer.textEnglish.split("|").map((color, index) => {
                        return (
                          <div
                            key={index}
                            className="color__item"
                            style={{ backgroundColor: color }}
                          ></div>
                        );
                      })}
                    </div>
                  ) : i18n?.language == "es" ? (
                    <span>{answer.textSpanish}</span>
                  ) : (
                    <span>{answer.textEnglish}</span>
                  )}
                </div>
              );
            })}
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="personality__test__container__body">
      <div className="personality__test__container__body__bordered__container">
        <div className="header">
          {isLanguageLoaded ? (
            <span>
              {i18n.language === "es"
                ? surveyData?.titleSpanish
                : surveyData?.titleEnglish}
            </span>
          ) : (
            <Skeleton width={"50%"} height={30} />
          )}
        </div>
        <div className="body">
          {questions.length > 0 ? (
            <div className="status__bar__container">
              <div className="status__bar__container__progress__text">
                <span>{`${t("personality__test__question__text1")} ${
                  currentQuestionsIndex + 1
                } ${t("personality__test__question__text2")} ${
                  questions.length
                }`}</span>
              </div>

              <div className="status__bar__container__bar__wrapper">
                <div
                  className="progress"
                  style={{
                    width: `${
                      ((currentQuestionsIndex + 1) * 100) / questions.length
                    }%`,
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <Skeleton width={150} height={24} />
              <Skeleton width={"100%"} height={26} />
            </div>
          )}

          <div className="questions__container">
            {questions.length > 0 ? (
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                modules={[Pagination, Navigation, EffectCoverflow]}
                style={{ width: "100%" }}
                autoHeight={true}
                allowTouchMove={false}
              >
                {renderQuestions()}
              </Swiper>
            ) : (
              <Skeleton width={"100%"} height={180} variant="rounded" />
            )}
          </div>
        </div>
        <div className="footer">
          {currentQuestionsIndex === 0 ? (
            <div className="footer__item"></div>
          ) : (
            <div
              className="footer__item"
              onClick={() => {
                handleGoToPrevSlide();
              }}
            >
              <ArrowBackIosNew />
              <span>{t("__login_Create_Account_Btn_Back_Text")}</span>
            </div>
          )}

          {currentQuestionsIndex === questions.length - 1 ? (
            <button
              className="footer__item final__button"
              disabled={
                surverAnswers[`question${questions.length - 1}`] === undefined
              }
              onClick={() => {
                setCurrentStepPage(1);
              }}
            >
              <span>{t("personality__test__question__finish__test")}</span>
            </button>
          ) : (
            <>
              {surverAnswers[`question${currentQuestionsIndex}`] !==
              undefined ? (
                <div
                  className="footer__item"
                  onClick={() => {
                    handleGoToNextSlide();
                  }}
                >
                  <span>{t("__login_Create_Account_Btn_Next_Text")}</span>
                  <ArrowForwardIos />
                </div>
              ) : (
                <div className="footer__item"></div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalityTestQuestions;
