import {
  REQUEST_SUBMIT_SURVEY_DATA,
  REQUEST_PERSONALITY_TEST_CONFIG,
  REQUEST_SURVEY_PROFILE_DETAILS,
  CLEAN_SURVEY_REDUCER
} from "./../../Constants";

// Acción para solicitar el contenido de la encuesta
export const requestSurveyContent = () => ({
  type: REQUEST_PERSONALITY_TEST_CONFIG,
  payload: "",
});

// Acción para enviar los datos de la encuesta
export const requestSubmitSurveyData = (model) => ({
  type: REQUEST_SUBMIT_SURVEY_DATA,
  payload: {
    model,
  },
});

// Acción para limpiar los datos de la encuesta y el sponsor
export const requestCleanSurveyReducer = () => {
  return {
    type: CLEAN_SURVEY_REDUCER,
    payload: {},
  };
};

// Acción para solicitar los detalles del perfil de la encuesta
export const requestSurveyProfileDetails = (model) => ({
  type: REQUEST_SURVEY_PROFILE_DETAILS,
  payload: {
    model,
  },
});