import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//MUI
import {
  FormControl,
  IconButton,
  InputLabel,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
//Datepicker
import moment from "moment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
//Utils
import {
  countries,
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
  restrictToOnlyNumbers,
} from "./../../../../Libraries/Utils/utils";
import { requestSubmitSurveyData, requestCleanSurveyReducer } from "../../../ReduxSaga/Actions/PersonalityTest";
import { requestTwilioOnlyCellPhoneValidation, cleanTwilioCellValidationReducer } from "../../../ReduxSaga/Actions/Twilio";
import { requestEmailValidation, cleanEmailReducerValidation } from "../../../ReduxSaga/Actions/Email";
import { useSnackbar } from "notistack";

const PersonalityTestForm = ({
  sponsorData,
  surveyData,
  surverAnswers,
  i18n,
  t
}) => {

  const history = useHistory();

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.personalityTest = useSelector((state) => state.personalityTest);
  appReducers.twilio = useSelector((state) => state.twilio);
  appReducers.email = useSelector((state) => state.email);

  const { enqueueSnackbar } = useSnackbar();

  //Hooks
  const [formData, setFormData] = useState({
    name: {
      error: false,
      isEmpty: true,
      value: "",
    },
    lastname: {
      error: false,
      isEmpty: true,
      value: "",
    },
    cellphone: {
      error: false,
      isEmpty: true,
      value: "",
      isValid: false,
      isVerified: false,
      cellphoneSelectedCountry: countries.find(
        (item) => item.shortName === "US"
      ),
    },
    email: {
      error: false,
      isEmpty: true,
      value: "",
      isValid: false,
      isVerified: false
    },
    genre: { error: false, value: "F" },
    birthdate: {
      error: false,
      isEmpty: false,
      value: moment().subtract(18, "years"),
    },
  });

  const [cellphoneCountryMenuAnchorEl, setCellphoneCountryMenuAnchorEl] = useState(null);

  //Effects

  // Efecto evaluar envio de formulario
  useEffect(() => {
    if (appReducers.personalityTest.errorSubmitSurveyAnswers !== undefined && !appReducers.personalityTest.errorSubmitSurveyAnswers) {
      if (appReducers.personalityTest.resultSurveyData) {
        dispatch(requestCleanSurveyReducer());
        history.replace(`/personalityTestResults/${appReducers.personalityTest.resultSurveyData.idPerson}/${appReducers.personalityTest.resultSurveyData.guid}`);
      } else {
        enqueueSnackbar(
          t("__myAccount_Profile_Message_error_Label"),
          {
            variant: "error",
          }
        );
      }
    }
  }, [
    appReducers.personalityTest.errorSubmitSurveyAnswers,
    appReducers.personalityTest.resultSurveyData,
  ]);

  //Effect on Twilio CellPhone Validation
  useEffect(() => {
    if (
      typeof appReducers?.twilio?.errorTwilioCellValidation !== "undefined"
    ) {
      if (!appReducers?.twilio?.errorTwilioCellValidation) {
        setFormData({
          ...formData,
          cellphone: {
            ...formData.cellphone,
            isValid: true,
            isVerified: true
          },
        });
      } else {
        setFormData({
          ...formData,
          cellphone: {
            ...formData.cellphone,
            isValid: false,
            isVerified: false
          },
        });
        enqueueSnackbar(t("__forms_twilio_cell_validation_type_error"), {
          variant: "error",
        });
      }
      dispatch(cleanTwilioCellValidationReducer());
    }
  }, [
    appReducers?.twilio?.errorTwilioCellValidation
  ]);

  useEffect(() => {
    if (
      typeof appReducers?.email?.errorEmailValidation !== "undefined" ||
      typeof appReducers?.email?.code !== "undefined"
    ) {
      if (!appReducers?.email?.errorEmailValidation) {
        setFormData({
          ...formData,
          email: {
            ...formData.email,
            isValid: true,
            isVerified: true,
          },
        });
      } else {
        setFormData({
          ...formData,
          email: {
            ...formData.email,
            isValid: false,
            isVerified: false,
          },
        });
        switch (appReducers?.email?.code) {
          case -100: //error de ejecucion
            enqueueSnackbar(
              t("__forms_email_validation_error_execution_message"),
              {
                variant: "error",
              }
            );
            break;
          case -200: //error email en bd
            enqueueSnackbar(
              t("__forms_email_validation_error_db_duplicity_message"),
              {
                variant: "error",
              }
            );
            break;
          case -900: //error zerobounce
            enqueueSnackbar(
              t("__forms_email_validation_error_zerobounce_message"),
              {
                variant: "error",
              }
            );
            break;
          default:
            enqueueSnackbar(
              t("__forms_email_validation_error_execution_message"),
              {
                variant: "error",
              }
            );
            break;
        }
      }
      dispatch(cleanEmailReducerValidation());
    }
  }, [appReducers?.email?.errorEmailValidation, appReducers?.email?.code]);

  //Handlers
  const handleCountryListElementClick = (e) => {
    const updatedText = e.target.innerText.split("\n").join("");
    const found = countries.find((element) => element.longName === updatedText);
    if (found) {
      setFormData({
        ...formData,
        cellphone: {
          ...formData.cellphone,
          cellphoneSelectedCountry: found,
          isValid: false,
          isVerified: false
        },
      });
      handleCellphoneBlur(found);
    }
    setCellphoneCountryMenuAnchorEl(null);
  };

  const handleCellphoneBlur = (cellphoneSelectedCountry) => {
    let isValid = formData.cellphone.value.length === 10;
    if (cellphoneSelectedCountry === undefined)
      cellphoneSelectedCountry = formData?.cellphone?.cellphoneSelectedCountry;
    if (isValid) {
      dispatch(requestTwilioOnlyCellPhoneValidation(formData?.cellphone?.value, cellphoneSelectedCountry?.shortName));
    } else {
      setFormData({
        ...formData,
        cellphone: {
          ...formData.cellphone,
          cellphoneSelectedCountry: cellphoneSelectedCountry,
          isValid: false,
          isVerified: false
        },
      });
    }
  };

  const handleEmailBlur = () => {
    setFormData({
      ...formData,
      email: {
        ...formData.email,
        isValid: regexIsEmailAddressValid(formData.email.value),
        isVerified: false,
      },
    });
    let isValid = regexIsEmailAddressValid(formData.email.value);
    if (isValid && formData.email.value.length !== 0) {
      dispatch(requestEmailValidation(formData.email.value, false));
    }
  };

  const handleSubmitForm = () => {
    const surverAnswersData = [];
    for (let i = 0; i < surveyData?.questions?.length; i++) {
      const splits = surverAnswers[`question${i}`].split('|');
      const answer = {
        idSurvey: Number(splits[0]),
        idQuestion: Number(splits[1]),
        idAnswer: Number(splits[2])
      };
      surverAnswersData.push(answer);
    }
    const person = {
      name: formData?.name?.value,
      lastName: formData?.lastname?.value,
      phone: formData?.cellphone?.value,
      email: formData?.email?.value,
      gender: formData?.genre?.value,
      birthdate: moment(formData?.birthdate?.value).format("YYYY-MM-DD"),
      idCountry: formData?.cellphone?.cellphoneSelectedCountry?.id,
      lada: formData?.cellphone?.cellphoneSelectedCountry?.internationalPrefix,
      idLang: i18n.language === "es" ? 1 : 2,
      sponsor: sponsorData?.idClient > 0 ? sponsorData?.idClient : 73780,
      idPage: sponsorData?.idClient > 0 && localStorage.getItem("ZUsaIdPage") ? Number(localStorage.getItem("ZUsaIdPage")) : 0
    };
    const model = {
      person: person,
      surveyData: surverAnswersData
    }
    dispatch(requestSubmitSurveyData(model));
  };

  return (
    <div className="personality__test__container__body">
      <div className="personality__test__container__body__bordered__container">
        <div className="header">
          <span>
            {
              i18n?.language === 'es'
                ? surveyData?.titleContactInformationSpanish
                : surveyData?.titleContactInformationEnglish
            }
          </span>
          <span>
            {
              i18n?.language === 'es'
                ? surveyData?.subtitleContactInformationSpanish
                : surveyData?.subtitleContactInformationEnglish
            }
          </span>
        </div>
        <div className="body">
          <div className="form__container">
            <div className="two__columns">
              <div className="input__wrapper">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="name">{t("__general__name")}</InputLabel>
                  <OutlinedInput
                    id="name"
                    endAdornment={
                      <InputAdornment position="end">
                        <div
                          className={
                            !formData.name.isEmpty ? "checkGreenIcon" : ""
                          }
                        />
                      </InputAdornment>
                    }
                    label={t("__general__name")}
                    required
                    error={formData.name.error || formData.name.isEmpty}
                    value={formData.name.value}
                    onInput={(e) => {
                      setFormData({
                        ...formData,
                        name: {
                          ...formData.name,
                          isEmpty: e.target.value.length <= 0,
                          value: e.target.value.toUpperCase(),
                        },
                      });
                    }}
                  />
                </FormControl>
              </div>
              <div className="input__wrapper">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="lastname">
                    {t("__new_members_form_lastName_label")}
                  </InputLabel>
                  <OutlinedInput
                    id="lastname"
                    endAdornment={
                      <InputAdornment position="end">
                        <div
                          className={
                            !formData.lastname.isEmpty ? "checkGreenIcon" : ""
                          }
                        />
                      </InputAdornment>
                    }
                    label={t("__new_members_form_lastName_label")}
                    required
                    error={formData.lastname.error || formData.lastname.isEmpty}
                    value={formData.lastname.value}
                    onInput={(e) => {
                      setFormData({
                        ...formData,
                        lastname: {
                          ...formData.lastname,
                          isEmpty: e.target.value.length <= 0,
                          value: e.target.value.toUpperCase(),
                        },
                      });
                    }}
                  />
                </FormControl>
              </div>
            </div>

            <div className="two__columns">
              <div className="input__wrapper">
                <div className="flag__wrapper">
                  {/* COUNTRY FLAGS */}
                  <IconButton
                    style={{
                      outline: "none",
                      width: 36,
                      height: 36,
                      padding: "0 12px 0 0",
                    }}
                    onClick={(e) => {
                      setCellphoneCountryMenuAnchorEl(e.currentTarget);
                    }}
                  >
                    <img
                      src={formData.cellphone.cellphoneSelectedCountry.flagURL}
                      alt="Flag"
                      style={{ width: "100%" }}
                    />
                  </IconButton>
                  <Menu
                    id="country-menu"
                    anchorEl={cellphoneCountryMenuAnchorEl}
                    keepMounted
                    open={Boolean(cellphoneCountryMenuAnchorEl)}
                    onClose={() => {
                      setCellphoneCountryMenuAnchorEl(null);
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: 300,
                      },
                    }}
                  >
                    {countries.map((country) => {
                      if (
                        country.id ===
                        formData.cellphone.cellphoneSelectedCountry.id
                      )
                        return (
                          <MenuItem
                            key={country.id}
                            selected
                            onClick={() => {
                              setCellphoneCountryMenuAnchorEl(null);
                            }}
                          >
                            {country.longName}
                          </MenuItem>
                        );
                      else
                        return (
                          <MenuItem
                            key={country.id}
                            onClick={handleCountryListElementClick}
                          >
                            {country.longName}
                          </MenuItem>
                        );
                    })}
                  </Menu>
                  {/* END COUNTRY FLAGS */}
                </div>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="phone">
                    {t("__general__phone")}
                  </InputLabel>
                  <OutlinedInput
                    id="phone"
                    endAdornment={
                      <InputAdornment position="end">
                        <div
                          className={
                            formData.cellphone.value.length !== 0 &&
                            formData.cellphone.isValid &&
                            formData.cellphone.isVerified
                              ? "checkGreenIcon"
                              : ""
                          }
                        />
                      </InputAdornment>
                    }
                    label={t("__general__phone")}
                    required
                    inputProps={{ maxLength: 10 }}
                    error={
                      formData.cellphone.error || formData.cellphone.isEmpty
                    }
                    value={formData.cellphone.value}
                    onBlur={() => {handleCellphoneBlur()}}
                    onInput={(e) => {
                      if (e.target.value.length <= 0) {
                        setFormData({
                          ...formData,
                          cellphone: {
                            ...formData.cellphone,
                            isValid: false,
                            isVerified: false,
                            error:
                              e.target.value.length > 0 &&
                              e.target.value.length !== 10,
                            isEmpty: e.target.value.length === 0,
                            value: restrictToOnlyNumbers(e, 10).toString(),
                          },
                        });
                      } else {
                        setFormData({
                          ...formData,
                          cellphone: {
                            ...formData.cellphone,
                            error:
                              e.target.value.length > 0 &&
                              e.target.value.length !== 10,
                            isEmpty: e.target.value.length === 0,
                            isValid: e.target.value.length === 10,
                            isVerified: false,
                            value: restrictToOnlyNumbers(e, 10).toString(),
                          },
                        });
                      }
                    }}
                  />
                </FormControl>
              </div>
              <div className="input__wrapper">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="email">
                    {t("__new_members_form_email_label")}
                  </InputLabel>
                  <OutlinedInput
                    id="email"
                    endAdornment={
                      <InputAdornment position="end">
                        <div
                          className={
                            formData.email.value.length !== 0 &&
                            formData.email.isValid &&
                            formData.email.isVerified
                              ? "checkGreenIcon"
                              : ""
                          }
                        />
                      </InputAdornment>
                    }
                    label={t("__new_members_form_email_label")}
                    required
                    error={formData.email.error || formData.email.isEmpty}
                    onBlur={handleEmailBlur}
                    onInput={(e) => {
                      if (e.target.value.length <= 0) {
                        setFormData({
                          ...formData,
                          email: {
                            ...formData.email,
                            isValid: false,
                            isVerified: false,
                            error: !regexIsEmailAddressValid(e.target.value),
                            isEmpty: e.target.value.length <= 0,
                            value: restrictToOnlyLettersForEmail(e),
                          },
                        });
                      } else {
                        setFormData({
                          ...formData,
                          email: {
                            ...formData.email,
                            error: !regexIsEmailAddressValid(e.target.value),
                            isEmpty: e.target.value.length <= 0,
                            isValid: regexIsEmailAddressValid(e.target.value),
                            isVerified: false,
                            value: restrictToOnlyLettersForEmail(e),
                          },
                        });
                      }
                    }}
                  />
                </FormControl>
              </div>
            </div>

            <div className="two__columns">
              <div className="input__wrapper">
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="gender">
                    {t("personality__test__form__fragrance__type")}
                  </InputLabel>
                  <Select
                    value={formData.genre.value}
                    label={t("personality__test__form__fragrance__type")}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        genre: {
                          ...formData.genre,
                          value: e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={"F"}>
                      {t("__ZUsa_NavBar_OptionCart_Gender_Female")}
                    </MenuItem>
                    <MenuItem value={"M"}>
                      {t("__ZUsa_NavBar_OptionCart_Gender_Male")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="input__wrapper">
                <FormControl fullWidth variant="outlined">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      fullWidth
                      closeOnSelect
                      label={t("__ZUsa_NavBar_OptionCart_BirthDtay")}
                      openTo="year"
                      format="YYYY-MM-DD"
                      maxDate={moment().subtract(18, "years").add(1, "days")}
                      size="small"
                      slotProps={{
                        textField: {
                          helperText: formData.birthdate.isEmpty
                            ? "Debes seleccionar una fecha"
                            : "",
                        },
                      }}
                      value={formData.birthdate.value}
                      onChange={(date) => {
                        setFormData({
                          ...formData,
                          birthdate: {
                            ...formData.birthdate,
                            isEmpty: date.length <= 0,
                            value: moment(date),
                          },
                        });
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </div>
            </div>

            <div className="form__footer">
              <button
                className="confirm__button"
                disabled={
                  formData.name.error ||
                  formData.name.isEmpty ||
                  formData.lastname.error ||
                  formData.lastname.isEmpty ||
                  formData.cellphone.error ||
                  formData.cellphone.value.length <= 0 ||
                  !formData.cellphone.isValid ||
                  !formData.cellphone.isVerified ||
                  formData.email.error ||
                  formData.email.isEmpty ||
                  !formData.email.isValid ||
                  !formData.email.isVerified ||
                  formData.genre.error ||
                  formData.birthdate.error ||
                  formData.birthdate.isEmpty
                }
                onClick={handleSubmitForm}
              >
                <span>{t("personality__test__form__get__results")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalityTestForm;
