import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import { useRef } from "react";
//MUI
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow } from "swiper";
import "swiper/swiper-bundle.css";

//Components
import PersonalityTestHeader from "./PersonalityTestHeader";
import PersonalityTestFooter from "./PersonalityTestFooter";

//Utils
import { truncateText } from "../../../../Libraries/Utils/utils";

// Actions
import { requestSurveyProfileDetails } from "../../../ReduxSaga/Actions/PersonalityTest";

// Alert
import { useSnackbar } from "notistack";
import { Skeleton } from "@mui/material";

const PersonalityTestResults = ({ match }) => {
  //Translation
  const { t, i18n } = useTranslation();

  //React Router
  const history = useHistory();

  //Swiper
  const swiperRef = useRef();

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.personalityTest = useSelector((state) => state.personalityTest);

  const { enqueueSnackbar } = useSnackbar();

  //Hooks
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const [surveyProfile, setSurveyProfile] = useState({});

  //Effects
  useEffect(() => {
    const uuid = match.params.uuid;
    const userId = match.params.userId;
    if (uuid && userId) {
      handleGetResults(userId, uuid);
    } else {
      handleRedirect();
    }
  }, []);

  // Efecto cargar datos del perfil
  useEffect(() => {
    if (
      typeof appReducers?.personalityTest?.errorLoadingProfileDetails !==
      undefined
    ) {
      if (!appReducers?.personalityTest?.errorLoadingProfileDetails) {
        if (appReducers?.personalityTest?.profileDetailsData) {
          setSurveyProfile(appReducers?.personalityTest?.profileDetailsData);
          // if (appReducers?.personalityTest?.profileDetailsData?.profile?.idLang == 1) {
          //   i18n.changeLanguage("es");
          // }
          if (
            appReducers?.personalityTest?.profileDetailsData?.survey === null
          ) {
            setTimeout(() => {
              enqueueSnackbar(
                t("__login_New_Account_Search_No_Found_One_label"),
                {
                  variant: "error",
                }
              );
              handleRedirect();
            }, 1000);
          }
        } else {
          setSurveyProfile({});
        }
      }
    }
    return () => {
      if (
        appReducers?.personalityTest?.profileDetailsData == null ||
        appReducers?.personalityTest?.profileDetailsData == undefined
      ) {
        setSurveyProfile({});
      }
    };
  }, [
    appReducers?.personalityTest?.errorLoadingProfileDetails,
    appReducers?.personalityTest?.profileDetailsData,
  ]);

  // Cargamos idioma
  useEffect(() => {
    if (i18n.resolvedLanguage) {
      setIsLanguageLoaded(true);
    }
  }, [i18n.resolvedLanguage]);

  //Handlers
  const handleGetResults = (idPerson, guid) => {
    const model = {
      idPerson: Number(idPerson),
      guid: guid,
    };
    dispatch(requestSurveyProfileDetails(model));
  };

  const handleRedirect = () => {
    history.replace(`/personalityTest`);
  };

  const handleGoToPrevSlide = () => {
    swiperRef.current.slidePrev();
  };

  const handleGoToNextSlide = () => {
    swiperRef.current.slideNext();
  };

  //Renders
  const renderProducts = () => {
    return surveyProfile?.products.map((item) => {
      return (
        <SwiperSlide key={item.id}>
          <div className="product__carousel__card">
            <div className="product__carousel__card__image__container">
              <img src={item.urlImage} alt="" />
            </div>
            <div className="product__carousel__card__title">
              {isLanguageLoaded ? (
                i18n.language === "es" ? (
                  <span>{truncateText(item.nameArticleSpanish, 30)}</span>
                ) : (
                  <span>{truncateText(item.nameArticleEnglish, 30)}</span>
                )
              ) : (
                <span></span>
              )}
            </div>
            <div className="product__carousel__card__subtitle">
              {isLanguageLoaded ? (
                i18n.language === "es" ? (
                  <span>{item.descArticleSpanish}</span>
                ) : (
                  <span>{item.descArticleEnglish}</span>
                )
              ) : (
                <span></span>
              )}
            </div>
            <div className="product__carousel__card__link">
              <span
                onClick={() => {
                  window.open(item.urlArticle, "_blank");
                }}
              >
                {t("personality__test__result__see__in__store")}
              </span>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="personality__test__container">
      <PersonalityTestHeader
        isLanguageLoaded={isLanguageLoaded}
        surveyData={surveyProfile?.survey}
        t={t}
        i18n={i18n}
      />

      <div className="personality__test__container__body">
        <div className="personality__test__container__body__bordered__container">
          <div className="header">
            {isLanguageLoaded ? (
              i18n.language === "es" ? (
                <span>{surveyProfile?.survey?.titleResultSpanish}</span>
              ) : (
                <span>{surveyProfile?.survey?.titleResultEnglish}</span>
              )
            ) : (
              <Skeleton width={"50%"} height={30} />
            )}
          </div>
          <div className="body">
            <div className="results__container">
              <div className="banner__image">
                {isLanguageLoaded &&
                surveyProfile &&
                Object.keys(surveyProfile).length > 0 ? (
                  <img
                    src={
                      isDesktop
                        ? i18n.language === "es"
                          ? surveyProfile?.profile?.urlImgSpanish
                          : surveyProfile?.profile?.urlImgEnglish
                        : i18n.language === "es"
                        ? surveyProfile?.profile?.urlImgSpanishMobile
                        : surveyProfile?.profile?.urlImgEnglishMobile
                    }
                    alt=""
                  />
                ) : (
                  <Skeleton variant="rectangular" width={"100%"} height={500} />
                )}
              </div>

              <div className="carousel__container">
                {surveyProfile &&
                surveyProfile?.products &&
                surveyProfile?.products.length > 0 ? (
                  <>
                    <div className="arrow__container">
                      <div
                        className="arrow__item left"
                        onClick={handleGoToPrevSlide}
                      >
                        <ArrowBackIosNew />
                      </div>
                    </div>
                    <Swiper
                      onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                      }}
                      modules={[Pagination, Navigation, EffectCoverflow]}
                      style={{ width: "100%", padding: "16px" }}
                      slidesPerView="1"
                      spaceBetween={20}
                      breakpoints={{
                        640: {
                          slidesPerView: "2",
                        },
                        1024: {
                          slidesPerView: "3",
                        },
                        1280: {
                          slidesPerView: "4",
                        },
                      }}
                    >
                      {renderProducts()}
                    </Swiper>
                    <div className="arrow__container">
                      <div
                        className="arrow__item right"
                        onClick={handleGoToNextSlide}
                      >
                        <ArrowForwardIos />
                      </div>
                    </div>
                  </>
                ) : (
                  <Swiper
                    style={{ width: "100%", padding: "16px" }}
                    slidesPerView="1"
                    spaceBetween={20}
                    breakpoints={{
                      640: {
                        slidesPerView: "2",
                      },
                      1024: {
                        slidesPerView: "3",
                      },
                      1280: {
                        slidesPerView: "4",
                      },
                    }}
                  >
                    {[0, 1, 2, 3].map((item) => {
                      return (
                        <SwiperSlide>
                          <Skeleton variant={"rounded"} height={476} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>

              <div className="carousel__footer">
                {isLanguageLoaded ? (
                  <button
                    onClick={() => {
                      window.open("https://www.zermatusa.com/home", "_blank");
                    }}
                  >
                    <span>{t("personality__test__result__go__to__zusa")}</span>
                  </button>
                ) : (
                  <Skeleton variant={"rounded"} width={200} height={43} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <PersonalityTestFooter isLanguageLoaded={isLanguageLoaded} />
    </div>
  );
};

export default PersonalityTestResults;
