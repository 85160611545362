import { call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_TWILIO_CELLPHONE_VALIDATION,
  RESPONSE_TWILIO_CELLPHONE_VALIDATION,
  REQUEST_TWILIO_CELLPHONE_CODE_VALIDATION,
  RESPONSE_TWILIO_CELLPHONE_CODE_VALIDATION,
  REQUEST_TWILIO_ONLY_CELLPHONE_VALIDATION,
  RESPONSE_TWILIO_ONLY_CELLPHONE_VALIDATION,
  DATA_FETCH_FAILED,
} from "./../../Constants";
import Twilio from "../../../../Libraries/Twilio";

function* requestTwilioCellPhoneValidation(data) {
  try {
    const response = yield call(
      Twilio.CellPhoneValidation,
      data.payload.cellPhone,
      data.payload.dbCheck,
      data.payload.typePlan
    );
    yield put({
      type: RESPONSE_TWILIO_CELLPHONE_VALIDATION,
      payload: { response: response.validateCellPhone },
    });
  } catch (error) {
    console.error("twilio cellphone validation", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestTwilioCellPhoneCodeValidation(data) {
  try {
    const response = yield call(
      Twilio.CellPhoneCodeValidation,
      data.payload.cellPhone,
      data.payload.code
    );
    yield put({
      type: RESPONSE_TWILIO_CELLPHONE_CODE_VALIDATION,
      payload: { response: response.validateCellPhoneCode },
    });
  } catch (error) {
    console.error("twilio cellphone code validation", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestTwilioOnlyCellPhoneValidation(data) {
  try {
    const response = yield call(
      Twilio.OnlyCellPhoneValidation,
      data.payload.cellPhone,
      data.payload.countryCode
    );
    yield put({
      type: RESPONSE_TWILIO_ONLY_CELLPHONE_VALIDATION,
      payload: { response: response.onlyValidateCellPhone },
    });
  } catch (error) {
    console.error("twilio only cellphone validation", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

export function* watchRequestTwilioCellPhoneValidation() {
  yield takeLatest(
    REQUEST_TWILIO_CELLPHONE_VALIDATION,
    requestTwilioCellPhoneValidation
  );
}
export function* watchRequestTwilioCellPhoneCodeValidation() {
  yield takeLatest(
    REQUEST_TWILIO_CELLPHONE_CODE_VALIDATION,
    requestTwilioCellPhoneCodeValidation
  );
}
export function* watchRequestTwilioOnlyCellPhoneValidation() {
  yield takeLatest(
    REQUEST_TWILIO_ONLY_CELLPHONE_VALIDATION,
    requestTwilioOnlyCellPhoneValidation
  );
}
