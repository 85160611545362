import React from "react";
import { useHistory } from "react-router-dom";
import { isDesktop } from "react-device-detect";
//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
//MUI
import { Skeleton } from "@mui/material";
//Assets
import { ReactComponent as ZermatLogoBlack } from "./../../../../assets/media/images/zermatLogoBlack.svg";
import { ReactComponent as PrevArrowLanguageSwitcher } from "./../../../../assets/media/images/icons/prevArrowLanguageSwitcher.svg";
import { ReactComponent as NextArrowLanguageSwitcher } from "./../../../../assets/media/images/icons/nextArrowLanguageSwitcher.svg";

const PersonalityTestHeader = ({
  isLanguageLoaded,
  t,
  i18n,
  surveyData,
  sponsorData,
}) => {
  const history = useHistory();

  return (
    <>
      {/* NAVBAR */}
      <div className="personality__test__container__navbar">
        <ZermatLogoBlack
          style={{ cursor: "pointer" }}
          onClick={() => {
            let target = "";
            if (localStorage.getItem("ZUsaUrlPage")) {
              target = `/mypage/${localStorage.getItem("ZUsaUrlPage")}`;
            } else {
              target = "/home";
            }
            if (target.length > 0) {
              history.push(target);
            }
          }}
        />
        <div className="navbar__floating__bar">
          <div className="language__switcher">
            <div className="prev">
              <PrevArrowLanguageSwitcher />
            </div>
            <Swiper
              id="languageSwiper"
              slidesPerView={1}
              updateOnWindowResize
              centeredSlides
              loop
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              onSlideChange={(swiper) => {
                if (swiper.realIndex === 0) {
                  i18n.changeLanguage("en");
                } else if (swiper.realIndex === 1) {
                  i18n.changeLanguage("es");
                }
              }}
              modules={[Navigation]}
            >
              <SwiperSlide>
                <span>
                  {!isLanguageLoaded ? (
                    <Skeleton
                      variant="rect"
                      width={50}
                      height={20}
                      style={{ margin: "0 5px" }}
                    />
                  ) : (
                    t("__general__navbar_language_english")
                  )}
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span>
                  {" "}
                  {!isLanguageLoaded ? (
                    <Skeleton
                      variant="rect"
                      width={50}
                      height={20}
                      style={{ margin: "0 5px" }}
                    />
                  ) : (
                    t("__general__navbar_language_spanish")
                  )}
                </span>
              </SwiperSlide>
            </Swiper>

            <div className="next">
              <NextArrowLanguageSwitcher />
            </div>
          </div>
        </div>
      </div>

      <div className="border__black"></div>

      {/* BANNER */}
      <div className="personality__test__container__banner__container">
        {isLanguageLoaded &&
        surveyData &&
        Object.keys(surveyData).length > 0 ? (
          <img
            src={
              isDesktop
                ? i18n.language === "es"
                  ? surveyData?.urlImgSpanish
                  : surveyData?.urlImgEnglish
                : i18n.language === "es"
                ? surveyData?.urlImgSpanishMobile
                : surveyData?.urlImgEnglishMobile
            }
            alt=""
          />
        ) : (
          <Skeleton variant="rectangular" width={"100%"} height={500} />
        )}
      </div>

      {/* WEBPAGE SPONSOR */}
      {sponsorData?.idClient ? (
        <div className="personality__test__container__sponsor__container">
          <div className="sponsor__wrapper">
            <span>{t("__form__new__members__name__sponsor")}</span>
            <span>{sponsorData?.nameClient}</span>
          </div>
        </div>
      ) : (
        <span></span>
      )}
    </>
  );
};

export default PersonalityTestHeader;
