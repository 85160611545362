import {
    RESPONSE_PERSONALITY_TEST_CONFIG,
    RESPONSE_SUBMIT_SURVEY_DATA,
    RESPONSE_SURVEY_PROFILE_DETAILS
} from "../../Constants";

export default function personalityTest(state = {}, action) {
    switch (action.type) {
        case RESPONSE_PERSONALITY_TEST_CONFIG: {
            const response = action.payload.response;
            if (response.code === 100) {
                return {...state, errorLoadingSurveyContent: false, surveyData: response.surveyData };
            } else {
                return {...state, errorLoadingSurveyContent: true };
            }
        }
        case RESPONSE_SUBMIT_SURVEY_DATA: {
            const response = action.payload.response;
            if (response.code === 100) {
                const splits = response.guid.split('|');
                const resultSurveyData = {
                    idPerson: splits[0],
                    guid: splits[1]
                };
                return {...state, errorSubmitSurveyAnswers: false, resultSurveyData: resultSurveyData };
            } else {
                return {...state, errorSubmitSurveyAnswers: true };
            }
        }
        case RESPONSE_SURVEY_PROFILE_DETAILS: {
            const response = action.payload.response;
            if (response.code === 100) {
                return {...state, errorLoadingProfileDetails: false, profileDetailsData: response.surveyResultDataModel };
            } else {
                return {...state, errorLoadingProfileDetails: true };
            }
        }
        default:
            return state;
    }
}
