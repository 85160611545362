import {
  CLEAN_REDUCER_CELLPHONE_VALIDATION,
  REQUEST_TWILIO_CELLPHONE_CODE_VALIDATION,
  REQUEST_TWILIO_CELLPHONE_VALIDATION,
  CLEAN_TWILIO_CELLVALIDATION_REDUCER,
  CLEAN_TWILIO_CELL_CODEVALIDATION_REDUCER,
  REQUEST_TWILIO_ONLY_CELLPHONE_VALIDATION
} from "./../../Constants";

export const requestTwilioCellPhoneValidation = (
  cellPhone,
  dbCheck,
  typePlan
) => {
  return {
    type: REQUEST_TWILIO_CELLPHONE_VALIDATION,
    payload: { cellPhone, dbCheck, typePlan },
  };
};

export const requestTwilioOnlyCellPhoneValidation = (
  cellPhone,
  countryCode
) => {
  return {
    type: REQUEST_TWILIO_ONLY_CELLPHONE_VALIDATION,
    payload: { cellPhone, countryCode },
  };
};

export const requestTwilioCellPhoneCodeValidation = (cellPhone, code) => {
  return {
    type: REQUEST_TWILIO_CELLPHONE_CODE_VALIDATION,
    payload: { cellPhone, code },
  };
};

export const cleanReducerCellPhoneDataValidation = () => {
  return {
    type: CLEAN_REDUCER_CELLPHONE_VALIDATION,
    payload: {},
  };
};

export const cleanTwilioCellValidationReducer = () => {
  return {
    type: CLEAN_TWILIO_CELLVALIDATION_REDUCER,
    payload: {},
  };
};

export const cleanTwilioCellCodeValidationReducer = () => {
  return {
    type: CLEAN_TWILIO_CELL_CODEVALIDATION_REDUCER,
    payload: {},
  };
};
